import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';


const languages = ['en', 'es'];

const LanguageSwitch = () => {

  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = e => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const changeLanguage = lang => {
    closeMenu();
    i18n.changeLanguage(lang);
  }

  return <>
    <IconButton onClick={openMenu} ><LanguageIcon /></IconButton>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={closeMenu}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
      {languages.map(lang => <MenuItem key={lang} onClick={() => changeLanguage(lang)} >{t(`header.languages.${lang}`)}</MenuItem>)}
      
    </Menu>
  </>
}

export default LanguageSwitch;