import React from "react";


const ComplaintsList = React.lazy(() => import('./pages/ComplaintsList'));
const ComplaintDetail = React.lazy(() => import('./components/ComplaintDetail'));
const SuggestionsList = React.lazy(() => import('./pages/SuggestionsList'));
const Statistics = React.lazy(() => import('./pages/Statistics'))

const routes = [
  { path: "/listado-denuncias", Component: ComplaintsList, role: 'complaint_officer' },
  { path: "/detalle-denuncia/:id", Component: ComplaintDetail, role: 'complaint_officer' },
  { path: "/listado-sugerencias", Component: SuggestionsList, role: 'complaint_officer' },
  { path: "/estadisticas", Component: Statistics, role: 'complaint_officer' },
];

export default routes;