import React, { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import { RHFTextFieldContainer } from '../ui/RHFTextField';
import RHFAccordionRadioGroup from '../ui/RHFAccordionRadioGroup';
import { useTranslation } from 'react-i18next';




const useStyles = makeStyles(theme => ({
  policy: {
    marginRight: '5px',
    display: 'flex',
    alignItems: 'center',
  },
}))

const ComplaintContactForm = () => {
  const { watch, getValues, control } = useFormContext();
  const { t } = useTranslation('translation', { keyPrefix: 'new_complaint.contact_form' })
  const [type, setType] = useState('INFORMED');
  const classes = useStyles();

  const options = [
    {label: t('type.option_1.label'), value: 'INFORMED', description: t('type.option_1.detail')},
    {label: t('type.option_2.label'), value: 'ANONYMOUS_WITH_TRACKING_CODE', description: t('type.option_2.detail')},
    {label: t('type.option_3.label'), value: 'ANONYMOUS', description: t('type.option_3.detail')},
  ];

  useEffect(() => {
    setType(getValues('type'))
  }, [watch('type')])

  return (
    <Grid container spacing={3} >
      <Grid item xs={12} ><Typography variant="h6" >{t('type.label')}</Typography></Grid>
      <Grid item xs={12} ><RHFAccordionRadioGroup defaultValue={'INFORMED'} rules={{ required: true }} options={options} name="type" /></Grid>

      {type === 'INFORMED' &&
        <>
          <Grid item xs={12} sm={6} lg={4} >
            <RHFTextFieldContainer name="name" label={t('name')} rules={{ required: true }} />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
            <RHFTextFieldContainer name="phone" label={t('phone')} rules={{ required: true }} />
          </Grid>
        </>
      }

      {type !== 'ANONYMOUS' &&
        <Grid item xs={12} lg={4} >
          <RHFTextFieldContainer name="email" label={t('email')} rules={{ required: true }} />
        </Grid>
      }

      <Grid item xs={12} >
        <Typography variant="subtitle1" className={classes.policy} >
          <Controller
            name="acceptPolicy"
            control={control}
            render={({ field }) => <Checkbox {...field} checked={field.value} />}
          />
          {t('read_privacy_policy')}
        </Typography>
        <Typography variant="subtitle1" color="primary" >
          <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer" >{t('privacy_policy')}</Link>
        </Typography>
      </Grid>

    </Grid>
  );
}

export default ComplaintContactForm;