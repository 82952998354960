import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";


const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: theme.spacing(3),
  },
  dialogContent: {
    padding: theme.spacing(1),
  },
  dialogActions: {
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
  },
}));


export default function LegalInfoModal({ visible, setVisible }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={visible} maxWidth="md" fullWidth="true">
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1"> En los supuestos de acciones u omisiones que puedan constituir infracciones
          del Derecho de la Unión Europea siempre que reúnan los requisitos:
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContent}>
          <Typography variant="subtitle1" gutterBottom> Oficina Europea de Lucha contra el Fraude (OLAF)</Typography>
          <Typography variant="body2">Información y los formularios necesarios para denunciar un fraude a la OLAF: </Typography>
          <Typography variant="body2" gutterBottom>
            <a href="https://anti-fraud.ec.europa.eu/olaf-and-you/report-fraud_es" target="_blank">https://anti-fraud.ec.europa.eu/olaf-and-you/report-fraud_es</a>
          </Typography>
          <br />
          <Typography variant="body2">Cualquier persona puede informar a esta oficina de sospechas de fraude o corrupción que afecten a los intereses financieros de la Unión Europea.</Typography>
          <Typography variant="body2" gutterBottom>Es posible dirigirse a la OLAF por medio de los siguientes canales:</Typography>
          <Typography variant="body2" gutterBottom>- En línea, en la siguiente web a través del sistema de notificaciones de fraude:
            <a href="https://fns.olaf.europa.eu/main_es.htm" target="_blank">https://fns.olaf.europa.eu/main_es.htm</a>
          </Typography>
          <Typography variant="body2" gutterBottom>- Por correo postal: <b>Comisión Europea, Oficina Europea de Lucha contra el Fraude (OLAF), Investigaciones y Operaciones B-1049 Bruselas, Bélgica.</b></Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color='primary' variant="contained"
          onClick={() => setVisible(false)}
          autoFocus
        >
          {t('new_onsite_complaint.modal.close_button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
