import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { complaints as apiComplaints } from '../../api';
import getDateString from '../../utils/getDateString';
import { ComplaintStatus, ComplaintStatusMap } from '../../common/constants/tenant-constants';


const useStyles = makeStyles(theme => ({
  field: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 30px',
    margin: '10px 0',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '10px',
    textAlign: 'left',
    backgroundColor: '#FFF',
  },
  fieldContent: {
    marginLeft: '20px',
    color: theme.palette.primary.main,
  },
  descriptionField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 30px',
    margin: '10px 0',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '10px',
    textAlign: 'left',
    backgroundColor: '#FFF',
  },
  descriptionFieldContent: {
    marginTop: '20px',
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    fieldContent: {
      margin: 0,
    },
    descriptionFieldContent: {
      margin: 0,
    },
  }
}));

const relationShipStatusMap = {
  EMPLOYEE: 'yes',
  NON_EMPLOYEE: 'no',
  UNDISCLOSED: 'undisclosed',
}

const ComplaintInfo = ({ complaint }) => {

  const { t } = useTranslation('translation', { keyPrefix: 'complaint_detail.fields' })
  const { me } = useSelector(store => store.root);
  const [audioURL, setAudioURL] = useState(null);
  const classes = useStyles();


  useEffect(() => {
    if (complaint.audioStorageName) generatePresignedAudioUrl();
    else setAudioURL(null);
  }, [complaint])


  const generatePresignedAudioUrl = async () => {
    try {
      const { data: url } = await apiComplaints.getPresignedAudioUrl(complaint.audioStorageName);
      setAudioURL(url);
    } catch (error) {

      console.error("Ocurrió un error recuperando el archivo de audio de la denuncia")
    }
  }



  return (
    <Grid container justifyContent="center" >
      {!me &&
        <Grid container item xs={12} className={classes.field} >
          <Grid item xs={12} md={4} >
            <Typography variant="subtitle1" >{t('status')}</Typography>
          </Grid>
          <Grid item xs={12} md={8} >
            <Typography variant="h6" className={classes.fieldContent} >
              {complaint.status === ComplaintStatus.EXPIRATED ? t(`${ComplaintStatusMap.CLOSED}`) : t(`${ComplaintStatusMap[complaint.status]}`)}
            </Typography>
          </Grid>
        </Grid>
      }

      <Grid container item xs={12} className={classes.field} >
        <Grid item xs={12} md={4} >
          <Typography variant="subtitle1" >{t('relationship_status.label')}</Typography>
        </Grid>
        <Grid item xs={12} md={8} >
          <Typography variant="h6" className={classes.fieldContent} >
            {t(`relationship_status.${relationShipStatusMap[complaint.relationshipStatus]}`)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.field} >
        <Grid item xs={12} md={4} >
          <Typography variant="subtitle1" >{t('department')}</Typography>
        </Grid>
        <Grid item xs={12} md={8} >
          <Typography variant="h6" className={classes.fieldContent} >{complaint.department}</Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.field} >
        <Grid item xs={12} md={4} >
          <Typography variant="subtitle1" >{t('involved')}</Typography>
        </Grid>
        <Grid item xs={12} md={8} >
          <Typography variant="h6" className={classes.fieldContent} >{complaint.involves}</Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.field} >
        <Grid item xs={12} md={4} >
          <Typography variant="subtitle1" >{t('location')}</Typography>
        </Grid>
        <Grid item xs={12} md={8} >
          <Typography variant="h6" className={classes.fieldContent} >{complaint.location}</Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.field} >
        <Grid item xs={12} md={4} >
          <Typography variant="subtitle1" >{t('address')}</Typography>
        </Grid>
        <Grid item xs={12} md={8} >
          <Typography variant="h6" className={classes.fieldContent} >{complaint.address}</Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.field} >
        <Grid item xs={12} md={4} >
          <Typography variant="subtitle1" >{t('happened_at')}</Typography>
        </Grid>
        <Grid item xs={12} md={8} >
          <Typography variant="h6" className={classes.fieldContent} >{getDateString(complaint.happenedAt)}</Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.descriptionField} >
        <Grid item xs={12} >
          <Typography variant="subtitle1" >{t('description')}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} >
          <Typography variant="h6" className={classes.descriptionFieldContent} >{complaint.description}</Typography>
        </Grid>
      </Grid>
      {audioURL &&
        <Grid container item xs={12} className={classes.field} >
          <Grid item xs={12} md={4} >
            <Typography variant="subtitle1" >{t('audio')}</Typography>
          </Grid>
          <Grid item xs={12} md={8} >
            <audio controls src={audioURL} controlslist="nodownload" />
          </Grid>
        </Grid>
      }
    </Grid>



  );
};

export default ComplaintInfo;