import { createTheme } from '@material-ui/core/styles';

const secondary = '#868686';
const grey = '#868686';

export default (theme) =>{
    const primary = theme.primaryColor;
    return createTheme({
            palette: {
                common: {
                    primary: `${theme.primaryColor}`,
                    secondary: `${secondary}`,
                },
                primary: {
                    main: `${primary}`,
                },
                secondary: {
                    main: `${secondary}`,
                },
                
            },
            typography: {
                    tab: {
                        fontFamily: 'Poppins',
                        textTransform: 'none',
                        fontWeight: 700,
                        fontSize: '1rem',
                    },
                    h1: {
                      fontFamily: 'Poppins',
                      fontWeight: "bold",
                      fontSize: "2rem",
                      color: primary,
                      lineHeight: 1.5
                    },
                    h2: {
                        fontFamily: 'Poppins',
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        color: secondary,
                    },
                    h3: {
                        fontFamily: 'Poppins',
                        fontSize: "1.75rem",
                        color: "black",
                    },
                    h4: {
                        fontFamily: 'Poppins',
                        fontSize: "1.5rem",
                        color: "white",
                        fontWight: 700
                    },
                    subtitle1: {
                        fontSize: "1.02rem",
                        fontWeight: 300,
                        color: `${grey}`,
                    },
                    subtitle2: {
                      fontSize: "1rem",
                      fontWeight: 300,
                      color: "white",
                    },
                    learnButton: {
                        borderColor: primary,
                        borderWidth: 2,
                        textTransform: "none",
                        color: primary,
                        borderRadius: 50,
                        fontFamily: 'Poppins',
                        fontWeight: "bold",
                    }
        
            },
            overrides: {
                MuiGrid:{
                  
                },
                MuiStepIcon: {
                  root: {
                    '&$active': {
                      fill: theme.primaryColor,
                      '& $text': {
                        fill: 'white',
                      },
                    },
                  },
                  text: {
                    fill: '#D3D3D3',
                  },
                },
                MuiBox: {
                  root: {
                                               
                  }
                },
                MuiOutlinedInput:{
                  multiline:{
                    
                  }
                }
              },
              
        });
} 
