import React from "react";
import { useSelector } from "react-redux";
import { useKeycloak } from '@react-keycloak/web';
import { CircularProgress } from "@material-ui/core";
import { Redirect } from "react-router";
import { useTranslation } from "react-i18next";

import { showErrorToast } from "../../utils/showToast";


export default ({ role, children }) => {
  const [keycloak, initialized] = useKeycloak();
  const { me } = useSelector(store => store.root);
  const { t } = useTranslation('translation', { keyPrefix: 'global' })

  if (!initialized) return <CircularProgress />;
  if (!keycloak.authenticated) return keycloak.login();
  if (keycloak.authenticated && !me) return <CircularProgress />;
  if (keycloak.authenticated && me) {
    if (role) {
      if (me.roles && me.roles.includes(role)) return children;
      return <Redirect to="/" />
    }
    return children;
  }

  showErrorToast(t('secured_route_error'));
  return <Redirect to="/" />;
}