import React from "react";
import { useTranslation } from 'react-i18next';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

import { SectionType } from "../../common/constants/tenant-constants";


const useStyles = makeStyles(theme => ({
  boxContainer: {
    marginTop: "20px",
    paddingLeft: "60px",
    paddingRight: "60px",
    paddingTop: "90px",
    paddingBottom: "30px",
    background:
      "linear-gradient(90deg, rgba(134,134,134,0.913585417076987) 98%, rgba(134,134,134,0.7567226719789478) 100%, rgba(0,212,255,1) 100%)",
    position: "absolute",
    zIndex: 1,
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      paddingLeft: "0px",
      marginTop: 0,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "0%",
      paddingTop: "80px",
      padding: "8px",
      paddingBottom: "30px",
    },
  },
  boxText: {
    marginTop: "10px",
    width: "80%",
    color: "white",
    padding: "20px",
    marginLeft: "10%",
    border: "1px solid white",
    borderRadius: "5px",
  },
}));

export default function Modal({ understood }) {
	const { t } = useTranslation();
  const { sections } = useSelector(state => state.root);
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Grid
        item
        container
        md={8}
        direction="column"
        justify="flex-end"
        className={classes.boxContainer}
        style={{ display: "block" }}
      >
        <Grid item md={12}>
          <Typography
            variant="h4"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(sections[SectionType.MODAL_TITLE] ?? ""),
            }}          
          />
        </Grid>
        <Grid item md={12} style={{ marginTop: 10 }}>
          <Typography
            variant="subtitle2"
            style={{ paddingTop: 20 }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(sections[SectionType.MODAL_DESCRIPTION] ?? ""),
            }}
          ></Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          md={12}
          className={classes.boxText}
        >
          <Typography
            style={{ paddingTop: 2 }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(sections[SectionType.MODAL_HELP] ?? ""),
            }}
          ></Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
        >
          <Button
            style={{ color: "white", marginTop: 30 }}
            variant="contained"
            color="primary"
            onClick={() => understood()}
          >
            { t('modal.agree') }
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
