import React, { useState } from 'react';
import { Container, Typography, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { default as LinkButton } from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import ComplaintOnSiteModal from "../components/ui/ComplaintOnSiteModal";
import LegalInfoModal from '../components/ui/LegalInfoModal';

import { SectionType } from '../common/constants/tenant-constants';


const useStyles = makeStyles(theme => ({
  container: {
    padding: "50px 10%",
  },
  title: {
    margin: '35px auto 25px',
  },
  description: {
    marginBottom: '30px',
  },
  bold: {
    fontWeight: 'bold',
    color: '#888',
  },
  text: {
    color: '#888',
  },
  buttonLink: {
    color: 'blue',
    marginLeft: "4px",
    fontSize: "0.875rem",
    verticalAlign: "top"
  }
}))


const Home = () => {
  const { sections } = useSelector(state => state.root);
  const { t } = useTranslation();
  const classes = useStyles();
  const [showComplaintOnSiteModal, setShowComplaintOnSiteModal] = useState(false);
  const [showLegalInfoModal, setShowLegalInfoModal] = useState(false);


  return (<>
    <Container maxWidth="lg" className={classes.container} >
      <Typography
        variant="h1"
        className={classes.title}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(sections[SectionType.HOME_TITLE] ?? ""),
        }}
      />
      <Typography
        variant="subtitle1"
        className={classes.description}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(sections[SectionType.HOME_DESCRIPTION] ?? ""),
        }}
      />
      <Typography variant="body2"
        className={classes.text}
        gutterBottom
      >
        Les informamos que para los supuestos previstos en la Ley 2/2023, de 20 de febrero, además de realizar la denuncia mediante el presente canal interno de la mercantil, puede realizar las denuncias, ante los siguientes organismos, para los siguientes
        <LinkButton
          component="button"
          variant="body2"
          className={classes.buttonLink}
          onClick={() => setShowLegalInfoModal(true)}
        >
          supuestos
        </LinkButton>
        .
      </Typography>
      <br />
      <Grid container spacing={4} style={{ maxWidth: '1200px' }} >
        <Grid item xs={12} >
          <Typography variant="h5" ><span className={classes.bold} >{t('home.actions_question')}</span></Typography>
        </Grid>
        <Grid item xs={12} sm={6} >
          <Button
            component={Link}
            to="/nueva-denuncia"
            fullWidth
            color="primary"
            variant="contained" >{t('home.issue_complaint')}</Button>
        </Grid>
        <Grid item xs={12} sm={6} >
          <Button
            onClick={() => setShowComplaintOnSiteModal(true)}
            fullWidth
            color="primary"
            variant="contained" >{t('home.issue_onsite_complaint')}</Button>
        </Grid>
        <Grid item xs={12} sm={6} >
          <Button
            component={Link}
            to="/casilla-segura-usuario"
            fullWidth
            color="primary"
            variant="outlined" >{t('home.check_complaint')}</Button>
        </Grid>
        <Grid item xs={12} sm={6} >
          <Button
            component={Link}
            to="/nueva-sugerencia"
            fullWidth
            color="primary"
            variant="outlined" >{t('home.issue_suggestion')}</Button>
        </Grid>
      </Grid>

    </Container>
    <ComplaintOnSiteModal visible={showComplaintOnSiteModal} setVisible={setShowComplaintOnSiteModal} />
    <LegalInfoModal visible={showLegalInfoModal} setVisible={setShowLegalInfoModal} />

  </>
  )
}
export default Home;