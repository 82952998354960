import api from "./api";

export default {
  setSessionToken: function (token) {
    if (token) {
      api.defaults.headers.common["Authorization"] = "Bearer " + token;
      return;
    }
    api.defaults.headers.common["Authorization"] = null;
  },
  getSessionToken: function () {
    return api.defaults.headers.common["Authorization"];
  },
  setHost: function (host) {
    api.defaults.headers.common["Domain"] = host;
  },
  me: function () {
    return api.get("/users/me");
  },
  loadingControl: function (loadingControl) {
    api.crowe = { setLoading: loadingControl };
  },
  getTheme: function () {
    return api.get("/theme");
  },
};
