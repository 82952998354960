import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioGroup, Radio, FormControlLabel, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const RHFRadioGroup = memo(({ methods, name, rules, direction, options, defaultValue }) => {

  const { t } = useTranslation();

  return <Controller
    name={name}
    rules={rules}
    control={methods.control}
    defaultValue={defaultValue ?? ''}
    render={({ field }) => (
      <>
        <RadioGroup {...direction} {...field} >
          {options.map(({ value, label }) => (
            <FormControlLabel key={value} value={value} label={label} control={<Radio />} />
          ))}
        </RadioGroup>
        {!!methods.formState.errors[name] && <FormHelperText error={!!methods.formState.errors[name]} >{methods.formState.errors[name]?.type && t('global.input_required_error')}</FormHelperText>}
      </>
    )}
  />
}, (prevProps, nextProps) => (
  prevProps.methods.formState.isDirty === nextProps.methods.formState.isDirty &&
  prevProps.methods.formState.errors !== nextProps.methods.formState.errors
))

const RHFRadioGroupContainer = props => {
  const methods = useFormContext();
  return <RHFRadioGroup methods={methods} {...props} />
}

export {
  RHFRadioGroup,
  RHFRadioGroupContainer,
};