import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  FormControlLabel,
  Typography,
  RadioGroup,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const RHFAccordionRadioGroup = ({ name, options, defaultValue }) => {
  const [expandedPanel, setExpandedPanel] = useState('');
  const { control } = useFormContext();
  const classes = useStyles();
  const expand = panel => setExpandedPanel(panel);

  return (
    <div className={classes.root}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        render={({ field }) => (
          <RadioGroup {...field} >
            {options?.map(option => (
              <Accordion key={option.value} expanded={expandedPanel === option.value} onChange={() => expand(option.value)} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="additional-actions1-header"
                >
                  <FormControlLabel
                    value={option.value}
                    label={option.label}
                    control={<Radio />}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="textSecondary">{option.description}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </RadioGroup>
        )}
      />
    </div>
  );
}

export default RHFAccordionRadioGroup;