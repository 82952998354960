import React from "react";
import { useSelector } from "react-redux";
import { AppBar, Container, IconButton, Toolbar } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";

import LanguageSwitch from "./ui/LanguageSwitch";
import Navbar from "./ui/Navbar";


const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		marginBottom: '65px',
	},
	logo: {
		maxHeight: "60px",
		maxWidth: "100px",
	},
	leftSide: {
		marginLeft: 'auto',
		display: 'flex',
		alignItems: 'center'
	}
}))

const Header = () => {
	const classes = useStyles();
	const { tenant } = useSelector(state => state.root);

  return (
    <div className={classes.root} >
			<AppBar color="inherit" position="fixed" >
				<Container maxWidth="lg">
					<Toolbar>
						<IconButton component={Link} to="/" edge="start" ><img className={classes.logo} src={tenant.logo} alt="logo" /></IconButton>
						<div className={classes.leftSide} >
							<Navbar />
							<LanguageSwitch />
						</div>
					</Toolbar>
				</Container>
			</AppBar>
		</div>
  );
}

export default Header;