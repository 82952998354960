import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import { SectionType } from '../common/constants/tenant-constants';


const useStyles = makeStyles(theme => ({
  container: {
    padding: "50px 10%",
  },
  title: {
    textAlign: 'center',
    margin: '30px auto 25px'
  },
}))

const PrivacyPolicy = () => {
  const { sections } = useSelector(store => store.root);
  const { t } = useTranslation('translation', { keyPrefix: 'global' });
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant='h1' className={classes.title}>{t('privacy_policy')}</Typography>
      <Typography
          variant="body"
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(sections[SectionType.PRIVACY_POLICY] ?? ""),
          }}
        />
    </Container>
  );
}

export default PrivacyPolicy;