import React from "react";
import { useTranslation } from 'react-i18next';
import { Container, Typography, Button, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

import { SectionType } from "../../common/constants/tenant-constants";


const useStyles = makeStyles(theme => ({
  boxContainer: {
    marginTop: "20px",
    paddingLeft: "60px",
    paddingRight: "60px",
    paddingTop: "90px",
    paddingBottom: "30px",
    background:
      "linear-gradient(90deg, rgba(134,134,134,0.913585417076987) 98%, rgba(134,134,134,0.7567226719789478) 100%, rgba(0,212,255,1) 100%)",
    position: "absolute",
    zIndex: 1,
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      paddingLeft: "0px",
      marginTop: 0,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "0%",
      paddingTop: "80px",
      padding: "8px",
      paddingBottom: "30px",
    },
  },
  boxText: {
    marginTop: "10px",
    width: "80%",
    color: "white",
    padding: "20px",
    marginLeft: "10%",
    border: "1px solid white",
    borderRadius: "5px",
  },
}));

export default function ComplaintOnSiteModal({ visible, setVisible }) {
  const { t } = useTranslation();
  const { sections } = useSelector(state => state.root);

  return (

    <Dialog open={visible} >
      <DialogTitle>{t('new_onsite_complaint.modal.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(sections[SectionType.MODAL_ONSITE_COMPLAINT_DESCRIPTION] ?? ""),
        }} />
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant="contained" onClick={() => setVisible(false)} >{t('new_onsite_complaint.modal.close_button')}</Button>
      </DialogActions>
    </Dialog>
  );
}
