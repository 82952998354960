import React, { useState } from 'react';
import { Grid, Container, Typography, TextField, Button, Divider, CircularProgress } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form'
import { showErrorToast, showSuccessToast } from '../utils/showToast';
import { suggestion } from '../api';


const useStyles = makeStyles(theme => ({
  container: {
    padding: '60px 10%',
  },
  title: {
    marginBottom: '15px'
  },
  inputContainer: {
    marginTop: '40px'
  },
  loader: {
    marginTop: '30px'
  }
}))

const NewSuggestion = () => {
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      lastName: '',
      description: '',
    }
  });

  const submit = async data => {
    setIsLoading(true);
    try {
      await suggestion.save(data);
      showSuccessToast("La sugerencia fue guardada con éxito.")
      history.push("/")
    } catch (error) {
      showErrorToast("Hubo un problema al guardar la sugerencia. Por favor inténtelo de nuevo")
      setIsLoading(false);
      return;
    }
  }

  return <Container maxWidth="lg" className={classes.container} >
    <Typography variant="h3" className={classes.title} >{t('new_suggestion.title')}</Typography>
    <Divider />

    {isLoading
      ? <Grid container justifyContent="center" className={classes.loader} ><CircularProgress /></Grid>
      : <form onSubmit={handleSubmit(submit)} >
          <Grid container spacing={4} className={classes.inputContainer} >
            <Grid item xs={12} sm={6} >
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) =>
                  <TextField
                    onChange={onChange} value={value} error={error && true} helperText={error && t('new_suggestion.input_error_helper')}
                    label={t('new_suggestion.name_input')} variant="outlined" fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={6} >
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) =>
                  <TextField
                    onChange={onChange} value={value} error={error && true} helperText={error && t('new_suggestion.input_error_helper')}
                    label={t('new_suggestion.last_name_input')} variant="outlined" fullWidth />
                }
              />
            </Grid>
            <Grid item xs={12} >
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) =>
                  <TextField
                    onChange={onChange} value={value} error={error && true} helperText={error && t('new_suggestion.input_error_helper')}
                    label={t('new_suggestion.suggestion_input')} variant="outlined" fullWidth multiline rows={5} />}
              />
            </Grid>

            <Grid item container spacing={3} justifyContent="space-between" className={classes.buttonContainer} >
              <Grid item xs={12} sm={5} md={4} >
                <Button component={Link} to="/" fullWidth variant="outlined" color="primary" >
                  {t('new_suggestion.go_back_button')}
                </Button>
              </Grid>
              <Grid item xs={12} sm={5} md={4} >
                <Button fullWidth variant="contained" color="primary" type="submit" >
                  {t('new_suggestion.save_suggestion')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      }
  </Container>
}

export default NewSuggestion;