import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core/styles';
import { KeycloakProvider } from "@react-keycloak/web";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Grid, CircularProgress } from '@material-ui/core'
import Helmet from 'react-helmet';

import { session } from './api';
import actionTypes from './redux/actions';
import createTenantTheme from './utils/createTenantTheme';
import Header from './components/Header';
import Modal from './components/ui/Modal';
import keycloak from "./keycloak";

import Home from './pages/Home';
import NewSuggestion from './pages/NewSuggestion';
import NewComplaint from './pages/NewComplaint';
import SecureUserSection from './pages/SecureUserSection';
import PrivacyPolicy from './pages/PrivacyPolicy';

import routes from './routes';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import SecureRoute from './components/auth/SecureRoute';
import { showErrorToast } from './utils/showToast';


const App = () => {
  
  const dispatch = useDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'global' });
  const { tenant } = useSelector(store => store.root);
  const [theme, setTheme] = useState({});
  const [started, setStarted] = useState(false);
  const [understood, setUnderstood] = useState(localStorage.getItem('cdd_understood'));

  useEffect(() => {
    getTheme()
  }, []);

  const getTheme = async () => {
    session.setHost(getSubdomain());
    try {
      const { data } = await session.getTheme();
      dispatch({ type: actionTypes.INIT_CACHE, payload: localStorage.getItem('cdd_cache') });
      dispatch({ type: actionTypes.INIT_TENANT, payload: data })
      setTheme(createTenantTheme(data));
      setStarted(true);
    } catch (error) {
      console.log(error);
    }
  }
  
  const getSubdomain = () => {
    let hostname = window.location.hostname.toLowerCase();
    return hostname;
  };

  const keycloakProviderInitConfig = {
    onLoad: "check-sso",
  };

  const onKeycloakEvent = event => {
    if (event === "onReady") {
      if(!keycloak.authenticated){
        dispatch({ type: "SESSION_INITIALIZED" });
      }
    }
  };

  const onKeycloakTokens = async ({ token }) => {
    session.setSessionToken(token);
    try {
      const { data: me } = await session.me();
      dispatch({ type: "SESSION_INITIALIZED" });
      dispatch({ type: "SESSION_ME", payload: me });
      dispatch({ type: "SESSION_WRONG_DOMAIN", payload: false });
    } catch (error) {
      if (error.response.status === 403) {
        showErrorToast(t('wrong_domain_toast'))
        dispatch({ type: "SESSION_WRONG_DOMAIN", payload: true });
      }
      dispatch({ type: "SESSION_ME" });
      dispatch({ type: "SESSION_INITIALIZED" });
    }
  };

  const saveUnderstood =()=> {
    setUnderstood(true);
    localStorage.setItem('cdd_understood', "true");
  }
    
  return (
    <div className="App">
      <KeycloakProvider
        keycloak={keycloak}
        initConfig={keycloakProviderInitConfig}
        onEvent={onKeycloakEvent}
        onTokens={onKeycloakTokens}
        >
          { started
            ? <ThemeProvider theme={theme}>
                { !understood
                    ? <Modal understood={saveUnderstood} theme={theme}/>
                    : null
                }
                <Helmet>
                  <title>{tenant && tenant.title ? tenant.title : 'CDD'}</title>
                  <link rel="icon" href={tenant.favicon} />
                  <link rel="icon" type="image/png" href={tenant.favicon} sizes="16x16" />
                  <link rel="apple-touch-icon" href={tenant.favicon}  />
                </Helmet>
                <Router>
                  <Header/>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/nueva-sugerencia" component={NewSuggestion} />
                    <Route exact path="/nueva-denuncia" component={NewComplaint} />
                    <Route exact path="/casilla-segura-usuario" component={SecureUserSection} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />

                    <Suspense fallback={<CircularProgress />} >
                      {routes.map(({ path, Component, role, exact }, index) =>
                        Component && (
                          <Route
                            key={index}
                            path={path}
                            exact={exact}
                            render={() => <SecureRoute role={role} ><Component /></SecureRoute>}
                          />
                        )
                      )}
                    </Suspense>
                  </Switch>
                </Router>  
              </ThemeProvider>
            : <Grid container justifyContent="center" style={{ marginTop: '40vh' }} ><CircularProgress /></Grid>
          }
      </KeycloakProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
