import React, { memo } from 'react';
import { TextField } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const RHFTextField = memo(({ methods, name, label, placeholder, type, rules, ...props }) => {
  const { t } = useTranslation();

  return (
    <TextField
      label={label ?? ''}
      placeholder={placeholder ?? t('global.input_placeholder')}
      variant="outlined"
      error={!!methods.formState.errors[name]}
      helperText={methods.formState.errors[name]?.type && t('global.input_required_error')}
      fullWidth
      type={type ?? "text"}
      {...methods.register(name, rules)}
      {...props}
    />
  )
}, (prevProps, nextProps) => {
  return (
    prevProps.methods.formState.isDirty === nextProps.methods.formState.isDirty &&
    prevProps.methods.formState.errors !== nextProps.methods.formState.errors
  )
})

const RHFTextFieldContainer = props => {
  const methods = useFormContext();
  return <RHFTextField methods={methods} {...props} />
}

export {
  RHFTextField,
  RHFTextFieldContainer,
};