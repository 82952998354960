import actionTypes from '../actions';

const initialState = {
  sections: {},
  me: undefined,
  initializing: true,
  tenant: undefined,
  wrongDomain: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_CACHE: {
      return state;
    }

    case actionTypes.INIT_TENANT: {
      const { sections: sectionsData, ...tenant } = action.payload;
      const sections = {};
      sectionsData.forEach(({ code, value }) => sections[code] = value)

      return {
        ...state,
        tenant,
        sections,
      };
    }

    case actionTypes.SET_SECTION: {
      let newState = { ...state };
      newState.sections[action.payload.code] = action.payload.sections;
      localStorage.setItem("cdd_cache", JSON.stringify(newState));
      return newState;
    }

    case actionTypes.SESSION_INITIALIZED: {
      return { ...state, initializing: false };
    }

    case actionTypes.SESSION_ME: {
      return { ...state, me: action.payload };
    }
    case actionTypes.SESSION_WRONG_DOMAIN: {
      return { ...state, wrongDomain: action.payload };
    }

    default:
      return state;
  }
}
