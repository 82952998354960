import axios from "axios";
const apiUrl = process.env.REACT_APP_API;

const instance = axios.create({
  baseURL: apiUrl,
});

instance.crowe = {};

axios.defaults.withCredentials = true;
instance.defaults.withCredentials = false;

instance.interceptors.request.use(async (config) => {
  if(instance.crowe.setLoading){
    instance.crowe.setLoading(true);
  }
  return config;
});

instance.interceptors.response.use(
  function (response) {
    if(instance.crowe.setLoading){
      instance.crowe.setLoading(false);
    }
    return response;
  },
  function (error) {
    if(instance.crowe.setLoading){
      instance.crowe.setLoading(false);
    }  
    if (
      error &&
      ((error.response &&
        (error.response.status === 401 || error.response.status === 403)) ||
        error.message === "Network Error") &&
      window.localStorage.getItem("loggedIn")
    ) { 
    }
    return Promise.reject(error);
  }
);

export default instance;
