export const SectionType = {
  HOME_DESCRIPTION: 'HOME_DESCRIPTION',
  HOME_TITLE: 'HOME_TITLE',
  MODAL_TITLE: 'MODAL_TITLE',
  MODAL_DESCRIPTION: 'MODAL_DESCRIPTION',
  MODAL_HELP: 'MODAL_HELP',
  MODAL_ONSITE_COMPLAINT_DESCRIPTION: 'MODAL_ONSITE_COMPLAINT_DESCRIPTION',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
}

export const Author = {
  COMPLIANCE_OFFICER: 'COMPLIANCE_OFFICER',
  PLAINTIFF: 'PLAINTIFF',
}

export const ComplaintStatus = {
  IN_RESEARCH: 'IN_RESEARCH',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
}

export const ComplaintType = {
  ANONYMOUS: 'ANONYMOUS',
  ANONYMOUS_WITH_TRACKING_CODE: 'ANONYMOUS_WITH_TRACKING_CODE',
  INFORMED: 'INFORMED'
}

export const ComplaintStatusMap = {
  IN_RESEARCH: 'in_research',
  CLOSED: 'closed',
  EXPIRATED: 'expirated',
  EXPIRED: 'expired',
}

export const ComplaintTypeMap = {
  ANONYMOUS: 'anonymous',
  ANONYMOUS_WITH_TRACKING_CODE: 'anonymous_with_tracking_code',
  INFORMED: 'informed'
}

export const ComplaintCategories = [
  'Tráfico ilegal de órganos humanos',
  'Trata de seres humanos',
  'Relativos a la prostitución y corrupción de menores',
  'Descubrimiento y revelación de secretos',
  'Estafas',
  'Insolvencias punibles',
  'Daños informáticos',
  'Contra la propiedad intelectual e industrial',
  'Contra el mercado y los consumidores',
  'Corrupción en los negocios',
  'Blanqueo de capitales',
  'Financiación ilegal de los partidos políticos',
  'Contra la Hacienda Pública y contra la Seguridad Social',
  'Contra los derechos de los ciudadanos extranjeros',
  'Contra la ordenación del territorio y el urbanismo',
  'Contra los recursos naturales y el medio ambiente',
  'Relativos a la energía nuclear y las radiaciones ionizantes',
  'Riesgos provocados por explosivos y otros agentes',
  'Contra la salud pública',
  'Contra la salud pública (tráfico de drogas)',
  'Falsificación de moneda y efectos de timbrado',
  'Falsificación de tarjetas de crédito y débito y cheques de viajes',
  'Cohecho',
  'Tráfico de influencias',
  'Malversación',
  'Delito de los derechos fundamentales y libertades públicas',
  'De las organizaciones y grupos criminales',
  'Financiación del terrorismo',
  'Contrabando',
  'Relativos a la manipulación genética',
  'Alteración de precios en concursos y subastas públicas',
  'Impedir la actuación de órganos inspectores',
  'Contra los derechos de los trabajadores',
  'Contra el código ético y de conducta de la compañía',
  'Relativos a la Protección de Datos de Carácter Personal - RGPD & LOPDGDD',
  'Otros'
]

export const SuggestionStatus = {
  NEW: 'NEW',
  CLOSED: 'CLOSED',
  IN_PROCESS: 'IN_PROCESS',
}

export const DetailBy = {
  "STATUS": "status",
  "CATEGORY": "category",
  "ASSIGNED_OFFICER": "assigned_officer",
}