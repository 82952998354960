import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { RHFTextFieldContainer } from '../ui/RHFTextField';
import { RHFRadioGroupContainer } from '../ui/RHFRadioGroup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { RHFAudioRecorderContainer } from "../ui/RHFAudioRecorder";

const ComplaintForm = () => {

  const { t } = useTranslation('translation', { keyPrefix: 'new_complaint.complaint_form' })
  const options = [
    { value: 'EMPLOYEE', label: t('relationship_status.yes') },
    { value: 'NON_EMPLOYEE', label: t('relationship_status.no') },
    { value: 'UNDISCLOSED', label: t('relationship_status.undisclosed') },
  ];

  return (
    <Grid container spacing={2} >

      <Grid item container xs={12} spacing={1} alignItems="center">
        <Typography variant="subtitle1" style={{ display: 'inline-block', marginRight: '20px' }} >{t('relationship_status.label')}</Typography>
        <RHFRadioGroupContainer direction={{ row: true }} rules={{ required: true }} options={options} name="relationshipStatus" />
      </Grid>

      <Grid item xs={12} md={6} >
        <Typography variant="subtitle1" >{t('department')}</Typography>
        <RHFTextFieldContainer rules={{ required: true }} name="department" />
      </Grid>
      <Grid item xs={12} md={6} >
        <Typography variant="subtitle1" >{t('involved')}</Typography>
        <RHFTextFieldContainer rules={{ required: true }} name="involves" />
      </Grid>
      <Grid item xs={12} md={4} >
        <Typography variant="subtitle1" >{t('location')}</Typography>
        <RHFTextFieldContainer rules={{ required: true }} name="location" />
      </Grid>
      <Grid item xs={12} md={4} >
        <Typography variant="subtitle1" >{t('address')}</Typography>
        <RHFTextFieldContainer rules={{ required: true }} name="address" />
      </Grid>
      <Grid item xs={12} md={4} >
        <Typography variant="subtitle1" >{t('happened_at')}</Typography>
        <RHFTextFieldContainer rules={{ required: true }} name="happenedAt" type="datetime-local" inputProps={{ max: moment().format('YYYY-MM-DDTHH:mm') }} />
      </Grid>
      <Grid item xs={12} >
        <Typography variant="subtitle1" >{t('description')}</Typography>
        <RHFTextFieldContainer rules={{ required: true }} name="description" multiline rows={5} />
      </Grid>

      <Grid item xs={12} >
        <Typography variant="subtitle1" >{t("audio")}</Typography>
        <RHFAudioRecorderContainer name="audioComplaint"/>
      </Grid>

    </Grid>
  );
}

export default ComplaintForm;