import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";

import { complaints as apiComplaints } from '../api';
import { showErrorToast } from "../utils/showToast";
import ComplaintDetail from "../components/ComplaintDetail";
import SecureUserSectionLogin from "../components/SecureUserSectionLogin";


const useStyles = makeStyles(theme => ({
  loader: {
    marginTop: '30px',
  },
}))

const SecureUserSection = () => {
  const [complaint, setComplaint] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const methods = useForm();

  const getComplaint = async complaint => {
    setIsLoading(true);
    try {
      const { data } = await apiComplaints.get(complaint);
      setComplaint(data)
    } catch (error) {
      showErrorToast("Hubo un problema al obtener la denuncia. Por favor inténtelo de nuevo")
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return (
    <Grid container justifyContent="center" className={classes.loader} >
      <CircularProgress />
    </Grid>
  );

  if (!complaint) return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit(getComplaint)} >
        <SecureUserSectionLogin />
      </form>
    </FormProvider>
  );

  return <ComplaintDetail complaint={complaint} />
}

export default SecureUserSection;