import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Button,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';


const useStyles = makeStyles(theme => ({
  navlink: {
    textTransform: 'capitalize',
    fontSize: '1rem',
    padding: '5px 15px',
    margin: '0 10px',
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  user: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    borderRadius: '5px',
    padding: '5px 15px',
    margin: '0 15px',
  },
  name: {
    marginRight: '5px',
    color: theme.palette.primary.main,
  }
}));

const Navbar = () => {
  const { initializing, me, wrongDomain } = useSelector(store => store.root);
  const { keycloak } = useKeycloak();
  const { t } = useTranslation('translation', { keyPrefix: 'header' });
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const classes = useStyles();

  useEffect(() => {
    if (wrongDomain) keycloak.logout();
  }, [wrongDomain])

	const handleSignInAndOut = () => {
		if (!me) {
			keycloak.login();
			return;
		}
		keycloak.logout();
    dispatch({ type: "SESSION_ME" });
    dispatch({ type: "SESSION_WRONG_DOMAIN", payload: false });
	}

  const openMenu = e => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  if (initializing) return <CircularProgress />;
  if (!me) return <Button onClick={handleSignInAndOut} >{t('login')}</Button>;
  if (isMobile) return (
    <>
      <IconButton onClick={openMenu} >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MenuItem component={Link} to="/listado-denuncias" >{t('complaints')}</MenuItem>
          <MenuItem component={Link} to="/listado-sugerencias" >{t('suggestions')}</MenuItem>
          <MenuItem component={Link} to="/estadisticas" >{t('statistics')}</MenuItem>
          <MenuItem onClick={handleSignInAndOut} >{t('logout')}</MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <Button className={classes.navlink} component={Link} to="/listado-denuncias" >{t('complaints')}</Button>
      <Button className={classes.navlink} component={Link} to="/listado-sugerencias" >{t('suggestions')}</Button>
      <Button className={classes.navlink} component={Link} to="/estadisticas" >{t('statistics')}</Button>

      <IconButton onClick={openMenu} className={classes.user} >
        <Typography variant="subtitle1" className={classes.name} >{me.user.firstName} {me.user.lastName}</Typography>
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MenuItem onClick={handleSignInAndOut} >{t('logout')}</MenuItem>
      </Menu>
    </>
  );
}

export default Navbar;