import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  boxGrid: {
    margin: '10px auto'
  },
  box: {
    padding: '30px 50px',
    textAlign: 'center'
  },
  emphasize: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}))

const ComplaintConfirmation = ({ code, pin }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'new_complaint.confirmation_form' })
  const classes = useStyles();

  return (
    <Grid container spacing={3} >
      <Grid item xs={12} >
        <Typography variant="h6" color="primary" >{t('title')}</Typography>
        <Typography variant="subtitle1" >{t('subtitle')}</Typography>
      </Grid>
      <Grid item className={classes.boxGrid} >
        <Paper elevation={3} className={classes.box} >
          <Typography variant="subtitle1" >{t('code')} <span className={classes.emphasize} >{code}</span></Typography>
          <Typography variant="subtitle1" >{t('pin')}<span className={classes.emphasize} >{pin}</span></Typography>
        </Paper>
      </Grid>

    </Grid>
  );
}

export default ComplaintConfirmation;