import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import { complaints as apiComplatints } from '../../api';
import { showErrorToast, showSuccessToast } from '../../utils/showToast';
import { ComplaintStatus, ComplaintCategories } from '../../common/constants/tenant-constants';


const useStyles = makeStyles(theme => ({
  officerInputs: {
    padding: '30px',
    backgroundColor: '#FFF',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '10px'
  },
  formControl: {
    width: '100%',
  },
  saveButton: {
    marginTop: '20px',
  },
}));

const ComplaintDetailEdit = ({ complaint: { id, status, category, assignedOfficer, createdAt }, users }) => {

  const classes = useStyles();
  const { me } = useSelector(store => store.root)
  const { t } = useTranslation('translation', { keyPrefix: 'complaint_detail.edit' })
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModa, setShowConfirmationModal] = useState(false);
  const [daysToAnonymize, setDaysToAnonymize] = useState(0);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      status,
      category,
      assignedOfficer,
    }
  })

  useEffect(() => {
    const creationDate = new Date(createdAt);
    creationDate.setDate(creationDate.getDate() + 90);
    const daysFromTodayToAnonymization = Math.round((creationDate.getTime() - new Date().getTime()) / (1000*60*60*24));
    if (daysFromTodayToAnonymization <= 0) setDaysToAnonymize(0);
    else setDaysToAnonymize(daysFromTodayToAnonymization)
  }, []);
  
  const submit = async data => {
    setIsLoading(true);
    try {
      await apiComplatints.update(id, data);
      showSuccessToast('La denuncia ha sido actualizada con éxito');
    } catch (error) {
      showErrorToast('Hubo un error al actualizar la denuncia');
      console.error(error)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} >
      <Grid container spacing={2} className={classes.officerInputs} >
        <Grid item xs={12} md={me ? 4 : 12} >
          <FormControl variant="outlined" className={classes.formControl} >
            <InputLabel id="status" >{t('status.label')}</InputLabel>
            <Select
              label={t('status.label')}
              labelId="status"
              id="status-select"
              defaultValue={status}
              {...register('status')}
              onChange={({ target: { value } }) => {
                if (value === ComplaintStatus.CLOSED) setShowConfirmationModal(true);
              }}
              >
                <MenuItem value={ComplaintStatus.IN_RESEARCH} >{t('status.researching')}</MenuItem>
                <MenuItem value={ComplaintStatus.CLOSED} >{t('status.closed')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4} >
          <FormControl variant="outlined" className={classes.formControl} >
            <InputLabel id="category" >{t('category.label')}</InputLabel>
            <Select
              label={t('category.label')}
              labelId="category"
              id="category-select"
              defaultValue={category}
              {...register('category')}
              >
                {ComplaintCategories.map(category => <MenuItem value={category} >{category}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4} >
          <FormControl variant="outlined" className={classes.formControl} >
            <InputLabel id="officer" >{t('assigned_officer.label')}</InputLabel>
            <Select
              label={t('assigned_officer.label')}
              labelId="officer"
              id="officer-select"
              defaultValue={assignedOfficer}
              {...register('assignedOfficer')}
              >
                { users?.map(({ id, firstName, lastName }) => <MenuItem key={id} value={id} >{`${firstName} ${lastName}`}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} container justifyContent="center" className={classes.saveButton} >
          <Grid item xs={12} sm={6} md={5} >
            <Button fullWidth type="submit" variant="contained" color="primary" >{isLoading ? <CircularProgress size={30} color='white' /> : t('save_button')}</Button>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={showConfirmationModa} >
        <DialogTitle>{t('close_complaint_confirmation_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('close_complaint_confirmation_content', { days: daysToAnonymize })}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='primary' variant="contained" onClick={() => setShowConfirmationModal(false)} >{t('close_complaint_confirmation_ok')}</Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default ComplaintDetailEdit;