import api from "./api";

export default {
  list: function () {
    return api.get("/admin/complaints");
  },
  getSummary: function (query) {
    return api.get("/admin/complaints/summary", { params: { ...query } })
  },
  getListToStatistics: function (query) {
    return api.get("/admin/complaints/list-statistics", { params: { ...query } })
  },
  get: function (complaint) {
    return api.post("/complaints/get", complaint);
  },
  save: function (complaint) {
    return api.post("/complaints", complaint);
  },
  getById: function (id) {
    return api.get(`/admin/complaints/${id}`);
  },
  update: function (id, data) {
    return api.put(`/admin/complaints/${id}`, data)
  },
  updateReadOfficer: function (id) {
    return api.post(`/admin/complaints/${id}/readOfficer`)
  },
  updateReadPlaintiff: function (id) {
    return api.post(`/complaints/${id}/readPlaintiff`)
  },
  getPresignedAudioUrl: function (storageName) {
    return api.get(`/complaints/audioURL/${storageName}`);
  },
};
