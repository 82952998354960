import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Container,
  Tabs,
  Tab,
  Typography,
  Divider,
  Button,
  Grid,
  CircularProgress,
  Badge
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from '../utils/showToast';
import { a11yProps, TabPanel } from '../utils/tabPanel';
import ComplaintInfo from './ui/ComplaintInfo';
import ComplaintDetailEdit from './ui/ComplaintDetailEdit';
import ComplaintMessages from './ui/ComplaintMessages';
import { complaints as apiComplaints } from '../api';


const useStyles = makeStyles(theme => ({
  container: {
    padding: '60px 5%',
    maxWidth: '900px',
    textAlign: 'center',
  },
  loader: {
    marginTop: '150px'
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    padding: '5px 30px',
  },
  tabHeader: {
    marginTop: '20px'
  },
  tabsContainer: {
    padding: '30px 0',
    borderRadius: '0 0 15px 15px'
  },
  [theme.breakpoints.up(1300)]: {
    container: {
      padding: '60px 0'
    }
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: '20px 0 0 0',
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
      flexDirection: 'column',
    },
    closeButton: {
      position: 'relative',
      marginBottom: '20px'
    }
  },
}));

const ComplaintDetail = ({ complaint: userComplaint }) => {
  
  const { me } = useSelector(store => store.root);
  const { t } = useTranslation('translation', { keyPrefix: 'complaint_detail' })
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(userComplaint ? false : true);
  const [showBadge, setShowBadge] = useState(userComplaint ? userComplaint.notReadPlaintiff : false);
  const [complaint, setComplaint] = useState(userComplaint ?? {});
  const [users, setUsers] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    if (id) getComplaint(id);
  }, [])

  const getComplaint = async id => {
    setIsLoading(true);
    try {
      const { data: { complaint, users } } = await apiComplaints.getById(id);
      if (complaint.notReadOfficer) setShowBadge(true);
      setComplaint(complaint);
      setUsers(users);
    } catch (error) {
      showErrorToast("Hubo un problema al obtener la denuncia. Por favor inténtelo de nuevo")
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) return (
    <Grid container justifyContent="center" className={classes.loader} >
      <CircularProgress />
    </Grid>
  );

  return (
    <Container maxWidth="lg" className={classes.container} >

      <div className={classes.header} >
        <Button
          className={classes.closeButton}
          variant="contained"
          color="primary"
          component={Link}
          to={me ? "/listado-denuncias" : "/"} >
            {me ? t('close_officer') : t('close_plaintiff')}
        </Button>
        <Typography variant="h1" >{t('title')}</Typography>
      </div>

      <Divider />

      <Tabs
        indicatorColor="primary"
        variant="fullWidth"
        className={classes.tabHeader}
        value={tabValue}
        onChange={(e, newValue) => setTabValue(newValue)}
        >
          <Tab label={t('tab1_title')} {...a11yProps(0)} />
          {complaint.type !== 'ANONYMOUS' &&
            <Tab {...a11yProps(1)}
              label={
                <Badge
                  color='error'
                  badgeContent={me ? complaint.notReadOfficer : complaint.notReadPlaintiff}
                  invisible={!showBadge}
                  >{t('tab2_title')}
                </Badge>
              }
            />
          }
          {me && <Tab label={t('tab3_title')} {...a11yProps(complaint.type !== 'ANONYMOUS' ? 2 : 1)} />}
      </Tabs>

      <Container className={classes.tabsContainer} >
        <TabPanel value={tabValue} index={0} >
          <ComplaintInfo complaint={complaint} />
        </TabPanel>
        {complaint.type !== 'ANONYMOUS' &&
          <TabPanel value={tabValue} index={1} >
            <ComplaintMessages complaint={complaint} setShowBadge={setShowBadge} />
          </TabPanel>
        }
        {me &&
          <TabPanel value={tabValue} index={complaint.type !== 'ANONYMOUS' ? 2 : 1} >
            <ComplaintDetailEdit complaint={complaint} users={users} />
          </TabPanel>
        }
      </Container>
    </Container>
  );
};

export default ComplaintDetail;