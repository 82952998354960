import React from "react";
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import InputAdornment from '@material-ui/core/InputAdornment';
import DialpadIcon from '@material-ui/icons/Dialpad';
import FiberPinIcon from '@material-ui/icons/FiberPin';

import { RHFTextFieldContainer } from '../components/ui/RHFTextField';


const useStyles = makeStyles(theme => ({
  container: {
    padding: '60px 10%',
    maxWidth: '900px',
    textAlign: 'center',
  },
  [theme.breakpoints.up(1300)]: {
    container: {
      padding: '60px 0 0 0'
    }
  },
  title: {
    color: theme.palette.primary.main
  },
  instruction: {
    margin: '15px 0 0px'
  },
  input: {
    width: '500px'
  },
  submit: {
    width: '200px',
    textAlign: 'center'
  }
}))

const SecureUserSectionLogin = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'secure_user_section' })
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container} >
      <Grid container spacing={4} justifyContent="center" >
        <Grid item xs={12} >
          <Typography className={classes.title} variant="h3" >{t('title')}</Typography>
        </Grid>
        <Grid item xs={12} >
          <Typography className={classes.instruction} variant="subtitle1" >{t('instruction')}</Typography>
        </Grid>

        <Grid item className={classes.input} >
          <RHFTextFieldContainer
            name="code"
            rules={{ required: true }}
            placeholder={t('code_input_placeholder')}
            label={t('code_input_label')}
            InputProps={{ startAdornment: <InputAdornment position="start" ><DialpadIcon /></InputAdornment> }}
          />
        </Grid>

        <Grid item className={classes.input} >
          <RHFTextFieldContainer
            name="pin"
            rules={{ required: true }}
            placeholder={t('pin_input_placeholder')}
            label={t('pin_input_label')}
            InputProps={{ startAdornment: <InputAdornment position="start" ><FiberPinIcon /></InputAdornment> }}
          />
        </Grid>

        <Grid item xs={12} >
          <Button className={classes.submit} variant="contained" color="primary" type="submit">{t('submit_button')}</Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SecureUserSectionLogin;