import api from "./api";

export default {
  get: function (id) {
    return api.get(`/messages/${id}`);
  },
  save: function (message) {
    return api.post("/messages", message);
  },
  getAttachment: function (complaintId, storageName) {
    return api.get(`/messages/attachment/${complaintId}/${storageName}`)
  },
};
