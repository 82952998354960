import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Grid, InputBase, IconButton, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SendIcon from '@material-ui/icons/Send';
import { Author } from '../../common/constants/tenant-constants';

import { messages as apiMessages, complaints as apiComplaints } from '../../api';
import { showErrorToast } from '../../utils/showToast';


const useStyles = makeStyles(theme => ({
  messageContainer: {
    padding: '0 10px',
    width: '100%',
    maxHeight: '45vh',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column-reverse',
    flexWrap: 'nowrap',
  },
  message: {
    backgroundColor: '#FFF',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '10px',
    width: '100%',
    padding: '10px 20px',
    margin: '10px 0',
    textAlign: 'left',
    height: 'auto'
  },
  messageRight: {
    marginLeft: 'auto'
  },
  messageHeader: {
    padding: '5px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  messageAuthor: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontStyle: 'italic'
  },
  messageContent: {
    margin: '10px 0',
  },
  messageAttachement: {
    color: theme.palette.primary.light,
    fontStyle: 'italic',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  inputContainer: {
    marginTop: '20px',
    padding: '20px',
    paddingBottom: '5px',
    backgroundColor: '#FFF',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  messageInput: {
    width: '100%',
    borderBottom: '1px solid #DDD',
    paddingBottom: '15px',
  },
  inputContainerBottom: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap'
  },
  attachmentName: {
    margin: 'auto 0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontStyle: 'italic'
  },
  sendIcon: {
    marginLeft: 'auto',
    marginRight: '5px'
  },
  attachmentInput: {
    display: 'none'
  },
  [theme.breakpoints.down('xs')]: {
    messageHeader: {
      flexDirection: 'column'
    },
  },
}));

const ComplaintMessages = ({ complaint: { id, messages: complaintMessages }, setShowBadge }) => {

  const classes = useStyles();
  const { me } = useSelector(store => store.root);
  const { t } = useTranslation('translation', { keyPrefix: 'complaint_detail.messages' })
  const [messages, setMessages] = useState(complaintMessages);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    updateReadMessages();
  }, [])

  const updateReadMessages = async () => {
    try {
      if (me) await apiComplaints.updateReadOfficer(id);
      else await apiComplaints.updateReadPlaintiff(id);
      setShowBadge(false);
    } catch (error) {
      console.error(error)
    }
  }

  const onFileChange = e => setFile(e.target.files[0]);
  
  const submitMessage = async () => {
    setLoading(true);
    try {
      const now = new Date();
      const date = `${now.getDate()}/${(now.getMonth() + 1)}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
      const data = { date, message, complaint: id, author: me ? Author.COMPLIANCE_OFFICER : Author.PLAINTIFF };
      let newMessage = new FormData();
      newMessage.append('file', file);
      newMessage.append('message', JSON.stringify(data));
      const { data: messages } = await apiMessages.save(newMessage)
      setMessages(messages);
      setMessage('');
      setFile(null)
    } catch (error) {
      showErrorToast('Hubo un error al enviar el mensaje. Por favor inténtelo de nuevo.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const downloadAtachment = async ({ storageName, originalName }) => {
    setLoading(true);
    try {
      const { data } = await apiMessages.getAttachment(id, storageName);
      
      let bytes = new Uint8Array(data.Body.data);
      let blob=new Blob([bytes], {type: data.ContentType});
      let link=document.createElement('a');
      
      link.href=window.URL.createObjectURL(blob);
      link.download=`${originalName}`;
      link.click();
    } catch (error) {
      showErrorToast('Hubo un error al obtener el adjunto. Por favor inténtelo de nuevo.');
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Grid container className={classes.messageContainer}  >

        {messages && messages.map(({ id, author, date, message, file }) => (
            <Grid key={id} container item xs={11} sm={10}
              className={`${classes.message} ${me
                ? author === Author.COMPLIANCE_OFFICER && classes.messageRight
                : author === Author.PLAINTIFF && classes.messageRight}`
              }
              >
              <Grid item xs={12} className={classes.messageHeader} >
                <Typography className={classes.messageAuthor}>{author === Author.COMPLIANCE_OFFICER ? t('complaint_officer') : t('plaintiff')}</Typography>
                <Typography>{date}</Typography>
              </Grid>
              <Grid item xs={12} >
                <Divider />
              </Grid>
              <Grid item xs={12} >
                <Typography className={classes.messageContent} variant="subtitle1" >
                  {message}
                </Typography>
              </Grid>
              <Grid item xs={12} >
                <Typography className={classes.messageAttachement} onClick={() => downloadAtachment(file)} >{file?.originalName}</Typography>
              </Grid>
            </Grid>
          ))
        }
      </Grid>

      <Grid container className={classes.inputContainer} >
        <InputBase
          value={message}
          onChange={({ target: { value } }) => setMessage(value)}
          className={classes.messageInput}
          color="primary"
          placeholder={t('input_placeholder')}
          fullWidth
          multiline
          maxRows={3}
        />
        
        <div className={classes.inputContainerBottom} >
          <label htmlFor="icon-button-file" className={classes.attachmentIcon} >
            <IconButton color="primary" aria-label="upload file" component="span" >
              <AttachFileIcon color="primary" />
            </IconButton>
          </label>

          {file &&
            <Typography className={classes.attachmentName} variant="subtitle1">
              {file.name}
            </Typography>
          }

          <IconButton onClick={submitMessage} className={classes.sendIcon} color="primary" aria-label="send message" component="span" >
            { loading ? <CircularProgress /> : <SendIcon color="primary" /> }
          </IconButton>

          <input
            name="attachment"
            accept="file/*"
            className={classes.attachmentInput}
            id="icon-button-file"
            type="file"
            onChange={onFileChange}
          />
        </div>
      </Grid>
    </>
  );
}

export default ComplaintMessages;