import React, { useState, useEffect, useRef, memo } from 'react';
import { Button, Typography, Backdrop, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
import ReplayIcon from '@material-ui/icons/Replay';
import MicIcon from '@material-ui/icons/Mic';
import DeleteIcon from '@material-ui/icons/Delete';
import StopIcon from '@material-ui/icons/Stop';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Pizzicato from 'pizzicato';
import PizzicatoRecorder from './PizzicatoRecorder';
import { showErrorToast } from '../../utils/showToast';
const lamejs = require("lamejsfix");


PizzicatoRecorder(Pizzicato);


const RECORD_STATE = {
  START: 'start',
  PROCESSING: 'processing',
  STOP: 'stop',
  NONE: 'none'
}

const distortionEffect = new Pizzicato.Effects.Distortion({
  distortion: 0.4,
});


const useStyles = makeStyles((theme) => ({
  animationMic: {
    animation: `$pulse 1s infinite ${theme.transitions.easing.easeInOut}`
  },
  "@keyframes pulse": {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  audioContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '300px',
  },
  backdrop: {
    zIndex: 9999,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(134, 134, 134, 0.9)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  distortionText: {
    color: 'black',
    marginTop: '20px', // Ajuste para el texto, si es necesario
  }
}));


const RHFAudioRecorderPizzicato = memo(({ methods, name, ...props }) => {
  const [recordState, setRecordState] = useState(RECORD_STATE.NONE);
  const [audioURL, setAudioURL] = useState();
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isMicEnabled, setIsMicEnabled] = useState(true);
  const [audioBlob, setAudioBlob] = useState(methods.getValues(name));//Inicializo el componente con valor
  const voiceRef = useRef(null);
  const timerIntervalRef = useRef(null);
  const timerDurationRef = useRef(null);

  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'new_complaint.complaint_form' });

  useEffect(() => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      if (!url) {
        showErrorToast(t("errorProcessingAudio"));
        deleteRecord(); //limpio todo lo correspondiente al audio;
        return;
      }

      setAudioURL(url); //Habilito la URL para reproductor
      methods.setValue(name, audioBlob);  //Guardo en el form item el dato del audio en blob
      setRecordState(RECORD_STATE.STOP);
    }
  }, [audioBlob]);


  const handleStartRecording = async () => {
    switch (recordState) {
      case RECORD_STATE.NONE:
        startAudio();
        break;

      case RECORD_STATE.START:
        stopAudio();
        break;
    }
  };

  //Método que activa el microfono y los defectos cuando se presiona Start en la grabacion
  const startAudio = () => {
    Pizzicato.context.resume(); //Esto habilita el uso del audiocontext por parte de Pizzicato ya que Chrome 77 en adelante bloquea las autoreproducciones
    Pizzicato.Recorder.start({ mute: true }); //Inicio el procesamiento con Pizzicato, pero en silencio

    var sound = new Pizzicato.Sound({ source: 'input' }, (err) => {
      if (err) {
        setIsMicEnabled(false); // onNotAllowedOrFound
        return console.error('Microfóno no habilitado o existente', err);
      }
      setIsMicEnabled(true);
      voiceRef.current = sound;
      sound.addEffect(distortionEffect);
      sound.play();
      setRecordState(RECORD_STATE.START);
      startTimer();
    });
  }

  const stopAudio = () => {
    stopTimer();
    setRecordState(RECORD_STATE.PROCESSING);
    Pizzicato.Recorder.stop('mp3', saveAudio);
    voiceRef.current.stop(); //Dejo de escuchar el Mic con Pizzicato
  }

  //Metodo que procesa el audio convirtiendo en Blob para enviarlo con el form
  async function saveAudio(fileOrBuffer, fileType) {

    let blobFile;
    if (fileType === 'mp3') {
      blobFile = wavToMp3Stereo(fileOrBuffer);
    }
    else {
      blobFile = fileOrBuffer;
    }

    setAudioBlob(blobFile);
  }

  //funcion para convertir bufferWav in Mp3 Stereo
  const wavToMp3Stereo = (buffer) => {
    const mp3encoder = new lamejs.Mp3Encoder(2, 44100, 128); //stereo 44.1khz encode to 128kbps

    const { l, r } = buffer;
    const left = new Int16Array(l);
    const right = new Int16Array(r);
    const sampleBlockSize = 1152; //can be anything but make it a multiple of 576 to make encoders life easier

    var mp3Data = [];
    for (var i = 0; i < left.length; i += sampleBlockSize) {
      let leftChunk = left.subarray(i, i + sampleBlockSize);
      let rightChunk = right.subarray(i, i + sampleBlockSize);
      let mp3buf = mp3encoder.encodeBuffer(leftChunk, rightChunk);
      if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
      }
    }
    var mp3buf = mp3encoder.flush();   //finish writing mp3

    if (mp3buf.length > 0) {
      mp3Data.push(new Int8Array(mp3buf));
    }

    return new Blob(mp3Data, { type: 'audio/mp3' });
  }


  /**
   *  Limpio todos los datos de la voz y del stream, y vuelvo el estado para volver a grabar
   */
  const deleteRecord = () => {
    voiceRef.current = null;
    setAudioURL(null);
    setRecordState(RECORD_STATE.NONE);
    timerIntervalRef.current = null; //Reinicio el timmer
    timerDurationRef.current = null; //Reinicio el timmer de duracion
    methods.setValue(name, undefined);//Limpio el valor del form
  }

  const startTimer = () => {
    // Iniciar el temporizador para detener la grabación después de 5 minutos
    const timer = setTimeout(() => stopAudio(), 300000); // 5 minutos en milisegundos
    timerDurationRef.current = timer;

    //Inicio el contador de seguindos de grabación
    setElapsedTime(0);
    timerIntervalRef.current = setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerIntervalRef.current);
    clearTimeout(timerDurationRef.current);
  };


  return (
    <>
      <Backdrop className={classes.backdrop} open={(recordState === RECORD_STATE.PROCESSING)}>
        <CircularProgress color="inherit" />
        <Typography variant="subtitle1" className={classes.distortionText} >{t("processingAudio")}</Typography>
      </Backdrop>
      <div>
        <Typography variant="caption" display="block">
          {t('audioExplanetion')}
        </Typography>
      </div>

      <div>
        {recordState === RECORD_STATE.START && (
          <>
            <div className={classes.audioContainer}>
              <MicIcon className={classes.animationMic} style={{ marginTop: "10px" }} />
              <Typography variant="caption" display="block">
                {moment.utc(elapsedTime * 1000).format('mm:ss')}
              </Typography>
              <div style={{ marginTop: "10px" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleStartRecording}
                  startIcon={<StopIcon style={{ color: "red" }} />}
                  size='small'
                >
                  {t("audioControl.stop")}
                </Button>
              </div>
            </div>
            <br />
            <div>
              <Alert severity="info">{t("alertInputSource")}</Alert>
            </div>
          </>
        )}

        {recordState === RECORD_STATE.NONE &&
          (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<MicIcon />}
              onClick={handleStartRecording}
            >
              {t("audioControl.start")}
            </Button>
          )}

        {recordState === RECORD_STATE.STOP && (
          <div className={classes.audioContainer}>
            <audio controls src={audioURL} controlslist="nodownload" />
            <div style={{ marginTop: "10px" }}>
              <Button variant="outlined" startIcon={<ReplayIcon />} size="small" onClick={deleteRecord}>
                {t("audioControl.retry")}
              </Button>
              <Button variant="outlined" startIcon={<DeleteIcon />} size="small" onClick={deleteRecord} style={{ marginLeft: '16px' }}>
                {t("audioControl.delete")}
              </Button>
            </div>
          </div>
        )}

        <div>
          {!isMicEnabled &&
            <Alert severity="warning">{t("alertMicNotEnabled")}</Alert>}
        </div>
      </div>
    </>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.methods.formState.isDirty === nextProps.methods.formState.isDirty &&
    prevProps.methods.formState.errors !== nextProps.methods.formState.errors
  )
})



const RHFAudioRecorderContainer = props => {
  const methods = useFormContext();
  return <RHFAudioRecorderPizzicato methods={methods} {...props} />
}

export {
  RHFAudioRecorderPizzicato,
  RHFAudioRecorderContainer,
};

